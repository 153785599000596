import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router';
import 'amfe-flexible/index';
import h5SwiperCss from "./assets/h5/css/swiper.less";
import pcSwiperCss from "./assets/pc/css/swiper.less";
import pcCss from "./assets/pc/css/common.less";
// import axios from 'axios';


const app = createApp(App);
app.use(router);
app.use(h5SwiperCss);
app.use(pcSwiperCss);
app.use(pcCss);
// app.use(axios);
// app.prototype.$axios = axios;





app.mount('#app')

